import { Filter } from '@my-toolbox-fe/type-filter';
import { codesBrands, codesCountries, codesLanguages } from '@my-toolbox-fe/type-search';

export function getFileExtension(value: File) {
  const extension = value.name.split('.').pop();

  if (extension) {
    return extension;
  }

  return '';
}

export function isFile(value: string | File): value is File {
  return (value as File).name !== undefined;
}

export function joinAndMapValues(values: Filter[]) {
  return values
    .map((item) => {
      return item.value;
    })
    .join(' ');
}

export function joinAndMapNames(values: Filter[]) {
  if (!values || !values.length) {
    return '-';
  }
  const mappedItem = values
    .map((item) => {
      return item.name;
    })
    .join(', ');

  return mappedItem;
}

export function mapValuesToArray(values: Filter[]) {
  return values.map((item) => {
    return item.value;
  });
}

export function mapLanguageCodes(values: Filter[]) {
  return values.map((item: Filter) => {
    return codesLanguages[item.value];
  });
}

export function mapCountryCodes(values: Filter[]) {
  return values.map((item: Filter) => {
    return codesCountries[item.value];
  });
}

export function mapLanguageCodesPropertyValues(values: Filter[]) {
  return values.map((item: Filter) => {
    return {
      ...item,
      name: codesLanguages[item.value],
    };
  });
}

export function mapBrandCodes(values: Filter[]) {
  return values.map((item: Filter) => {
    return codesBrands[item.value];
  });
}

export function filterItems(filterValues: string[], filterList: Filter[]) {
  if (!filterList) {
    return [];
  }
  const filteredList = filterValues.flatMap((filterValue) => {
    return filterList.filter(
      (filterItem) => filterItem.value === filterValue || filterItem.name === filterValue,
    );
  });

  return filteredList.map((item) => {
    return {
      ...item,
      item: item.value.replace(/_/g, ' '),
    };
  });
}

export function filterItemsLanguage(filterValues: string[], filterList: Filter[]) {
  if (!filterList) {
    return [];
  }
  const filteredList = filterValues.flatMap((filterValue) => {
    return filterList.filter((filterItem) => filterItem.value === filterValue);
  });

  return filteredList.map((item) => {
    return {
      ...item,
      name: codesLanguages[item.value],
    };
  });
}

export function findPropertyValue(filterValue: string, filterList: Filter[]) {
  const item = filterList.find((val) => filterValue === val.value);

  if (item) {
    return {
      code: item.code,
      name: item.name,
      value: item.value.replace(/_/g, ' '),
    };
  }

  return { code: '', name: '', value: '' };
}

export function findOptionalPropertyValue(filterValue: string | null, filterList: Filter[]) {
  const item = filterList.find((val) => filterValue === val.value);

  if (item) {
    return item;
  }

  return null;
}
