import { inject, Injectable, signal, WritableSignal } from '@angular/core';
import {
  FileInformation,
  FormMode,
  FormSig,
  Organization,
  ProductInformation,
  UploadOptionsFilters,
} from '@my-toolbox-fe/type-upload';
import { Filter } from '@my-toolbox-fe/type-filter';
import { UploadFormTypesService } from './upload-form-types.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UploadFormStateService {
  private uploadFormTypesService = inject(UploadFormTypesService);

  formFunction = signal(false);

  isLoadingSig = signal(false);

  isFormEditableSig = signal(false);

  fileIdSig: WritableSignal<number> = signal(0);

  isSingleBrandValidSig = signal(false);

  isNextStepDisabled = signal(false);

  isFormCompleted = new BehaviorSubject<boolean>(false);

  formModeSig: WritableSignal<FormMode> = signal('create');

  currentStep: WritableSignal<number> = signal(0);

  contentTypeDefaultVisibility: WritableSignal<string> = signal('');

  formOptionsSig: WritableSignal<UploadOptionsFilters> = signal({
    asset_audience: [],
    brand: [],
    business_line: [],
    category: [],
    country: [],
    language: [],
    measure: [],
    product_model: [],
    product_company: [],
    product_range: [],
    product_subrange: [],
    sb_classification: [],
    type: [],
    visibility: [],
    restricted_role: [],
  });
  visibleTypesSig: WritableSignal<Filter[]> = signal([]);

  formFileSig: WritableSignal<FileInformation> = signal({
    title: '',
    realFileName: '',
    uploadFile: '',
  });

  formSig: WritableSignal<FormSig> = signal({
    audience: '',
    visibility: '',
    languages: [],
    category: '',
    contentType: '',
    description: '',
    externalUrl: null,
    documentNumber: null,
    serviceBulletinClassification: null,
    serviceBulletinNumber: null,
    measures: [],
    validFrom: null,
    validTo: null,
    productRelated: false,
    syncWithShowPad: false,
  });

  formOrganizationSig: WritableSignal<Organization> = signal({
    productCompanies: [],
    brands: [],
    businessLines: [],
    countries: [],
    restrictedRoles: [],
  });

  formProductSig: WritableSignal<ProductInformation> = signal({
    serialNumbers: [],
    partNumbers: [],
    productNames: [],
    productModels: [],
    productSubranges: [],
    productRanges: [],
  });

  setFileId(value: number) {
    this.fileIdSig.set(value);
  }

  setFormOptions(values: UploadOptionsFilters) {
    this.formOptionsSig.set(values);
  }

  setCurrentStep(value: number) {
    this.currentStep.set(value);
  }

  setIsLoading(value: boolean) {
    this.isLoadingSig.set(value);
  }

  setIsFormCompleted(value: boolean) {
    this.isFormCompleted.next(value);
  }

  setIsFormEditable(value: boolean) {
    this.isFormEditableSig.set(value);
  }

  setFormMode(value: FormMode) {
    this.formModeSig.set(value);
  }

  setIsNextStepDisabled(value: boolean) {
    this.isNextStepDisabled.set(value);
  }

  setVisibleTypes(values: Filter[]) {
    this.visibleTypesSig.set(values);
  }

  resetVisibleTypes() {
    this.visibleTypesSig.set(this.formOptionsSig().type);
  }

  setCategory(cat: string) {
    this.formSig.set({
      ...this.formSig(),
      category: cat,
    });
  }

  setFormOptionsTypeMatchingCategory(categoryId: string) {
    const types = this.uploadFormTypesService.getFilteredCategoryContentType(
      categoryId,
      this.formOptionsSig().type,
    );
    this.setVisibleTypes(types);
  }

  setCategoryMatchingType(categoryId: string) {
    const category = this.uploadFormTypesService.getCategoryMatchingType(
      categoryId,
      this.formOptionsSig().category,
    );
    if (!category) {
      return;
    }
    this.setCategory(category[0].value);
  }

  setDefaultVisibility(defaultVisibility: string) {
    this.contentTypeDefaultVisibility.set(defaultVisibility);
    this.formSig.set({
      ...this.formSig(),
      visibility: defaultVisibility,
    });
  }

  setIsSingleBrandValid(value: boolean) {
    this.isSingleBrandValidSig.set(value);
  }

  setShowPadSync() {
    const filteredItem = this.formOptionsSig().type.find((item: Filter) => {
      return item.value === this.formSig().contentType;
    });

    this.formSig.set({
      ...this.formSig(),
      syncWithShowPad: filteredItem?.uploadFields?.includes('syncWithShowpad') ?? false,
    });
  }

  setForm(values: FormSig) {
    this.formSig.set(values);
  }

  setOrganization(values: Organization) {
    this.formOrganizationSig.set(values);
  }

  clearOrganization() {
    this.formOrganizationSig.set({
      productCompanies: [],
      brands: [],
      countries: [],
      businessLines: [],
      restrictedRoles: [],
    });
  }

  setProductInformation(values: ProductInformation) {
    this.formProductSig.set(values);
  }

  clearProductInformation() {
    this.formProductSig.set({
      serialNumbers: [],
      partNumbers: [],
      productNames: [],
      productModels: [],
      productSubranges: [],
      productRanges: [],
    });
  }

  resetProductVerificationSuggestion() {
    this.clearOrganization();
    this.clearProductInformation();
    this.setIsSingleBrandValid(true);
  }

  setFileTitle(title: string) {
    this.formFileSig().title = title;
  }

  setRealFileName(name: string) {
    this.formFileSig().realFileName = name;
  }

  clearPartialFormState() {
    this.formSig.set({
      audience: '',
      visibility: '',
      languages: [],
      category: this.formSig().category,
      contentType: this.formSig().contentType,
      description: '',
      externalUrl: null,
      documentNumber: null,
      serviceBulletinClassification: null,
      serviceBulletinNumber: null,
      measures: [],
      validFrom: null,
      validTo: null,
      productRelated: false,
      syncWithShowPad: false,
    });

    this.formFileSig.set({
      title: '',
      realFileName: '',
      uploadFile: this.formFileSig().uploadFile,
    });

    this.clearProductInformation();
    this.clearOrganization();
    this.resetProductVerificationSuggestion();
  }

  clearFormState() {
    this.setCurrentStep(0);

    this.formSig.set({
      audience: '',
      visibility: '',
      languages: [],
      category: '',
      contentType: '',
      description: '',
      externalUrl: null,
      documentNumber: null,
      serviceBulletinClassification: null,
      serviceBulletinNumber: null,
      measures: [],
      validFrom: null,
      validTo: null,
      productRelated: false,
      syncWithShowPad: false,
    });

    this.formFileSig.set({
      title: '',
      realFileName: '',
      uploadFile: '',
    });

    this.clearProductInformation();
    this.clearOrganization();

    this.resetVisibleTypes();
  }
}
