import { Component, inject, OnInit, ViewChild, WritableSignal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectUiComponent } from '@my-toolbox-fe/ui-elements';
import { FormsModule, NgForm } from '@angular/forms';
import {
  ProductVerificationService,
  UploadFormStateService,
  UploadFormAllowedFieldsService,
} from '@my-toolbox-fe/data-access-upload';
import { Filter } from '@my-toolbox-fe/type-filter';
import { FormSig, UploadOptionsFilters } from '@my-toolbox-fe/type-upload';
import { environment } from '@my-toolbox-fe/environments/environment';
@Component({
  selector: 'mt-upload-form-steps-category',
  standalone: true,
  imports: [CommonModule, SelectUiComponent, FormsModule],
  templateUrl: './upload-form-steps-category.smart-component.html',
  styleUrl: './upload-form-steps-category.smart-component.scss',
})
export class UploadFormStepsCategorySmartComponent implements OnInit {
  @ViewChild('form') ngForm: NgForm | null = null;

  protected productVerificationService = inject(ProductVerificationService);
  protected uploadFormStateService = inject(UploadFormStateService);

  protected uploadFormAllowedFieldsService = inject(UploadFormAllowedFieldsService);

  formSig: WritableSignal<FormSig> = this.uploadFormStateService.formSig;
  formOptionsSig: WritableSignal<UploadOptionsFilters> = this.uploadFormStateService.formOptionsSig;
  visibleTypesSig: WritableSignal<Filter[]> = this.uploadFormStateService.visibleTypesSig;

  isFormEditableSig = this.uploadFormStateService.isFormEditableSig;

  isCategoryDisabled = true;

  constructor() {
    const currentContentType = this.formSig().contentType;
    const currentVisibility = this.formSig().visibility;
    const matchingFilter = this.visibleTypesSig().find(
      (filter) => filter.value === currentContentType,
    );
    if (matchingFilter) {
      this.uploadFormStateService.setDefaultVisibility(currentVisibility);
    }
  }

  ngOnInit() {
    this.uploadFormStateService.setIsNextStepDisabled(false);
    this.setIsCategoryDisabled();
  }

  setIsCategoryDisabled() {
    this.isCategoryDisabled = !environment.categoryEnabled && this.isFormEditableSig();
  }

  onSelectCategoryEvent(event: Filter[]) {
    if (!event.length) {
      return;
    }
    const id = event[0].value;
    this.uploadFormStateService.setFormOptionsTypeMatchingCategory(id);
    this.uploadFormStateService.setForm({ ...this.formSig(), contentType: '' });
  }

  onSelectTypeEvent(event: Filter[]) {
    if (!event.length) {
      return;
    }

    const id = event[0].categoryId?.toString();
    const value = event[0].value;
    const defaultVisibility = event[0].defaultVisibility;

    if (!id) {
      return;
    }

    if (defaultVisibility) {
      this.uploadFormStateService.setDefaultVisibility(defaultVisibility);
    }

    this.uploadFormAllowedFieldsService.updateAllowedFields(value);
    this.uploadFormStateService.setShowPadSync();
    this.uploadFormStateService.setCategoryMatchingType(id);
    this.uploadFormAllowedFieldsService.updateAllowedFieldsValues();
  }
}
